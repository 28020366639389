<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card elevation="0" max-width="1100" width="100%" class="transparent mb-5">
        <v-toolbar flat class="transparent mb-3">
          <h1 class="headline black--text">{{ $t('menu.empresas') }}</h1>
          <v-spacer />
          <v-text-field
            data-test="search-company"
            v-if="!loading"
            v-model="search"
            clearable
            filled
            rounded
            flat
            dense
            prepend-inner-icon="mdi-magnify"
            :placeholder="this.$t('empresa.buscar')"
            hide-details
            class="mr-2"
          />
          <v-btn
            data-test="new-company"
            depressed
            rounded
            color="primary"
            @click="mostrarModal('nuevo', 0)"
          >
            {{ $t('empresa.nueva') }}
          </v-btn>
        </v-toolbar>
        <v-card>
          <div v-if="loading" class="pa-5 text-center">
            <v-progress-circular width="2" color="primary" indeterminate />
          </div>
          <v-data-table
            v-if="!loading"
            :headers="headers"
            :search="search"
            :items="empresas"
            hide-default-footer
            :page.sync="page"
            :items-per-page="itemsPerPage"
            :options="{ sortBy: ['created_at'], sortDesc: [true] }"
            item-key="name"
            @page-count="pageCount = $event"
          >
            <template v-slot:item="props">
              <tr style="cursor: pointer">
                <td @click="abrirFicha(props.item.id)">
                  {{ props.item.nombre === null ? props.item.name : props.item.nombre }}
                </td>
                <td @click="abrirFicha(props.item.id)">
                  {{ props.item.email_admin }}
                </td>
                <td class="text-center" @click="abrirFicha(props.item.id)">
                  <v-icon
                    v-if="props.item.estado_noty_pacientes"
                    v-tooltip="$t('empresa.compartir_pacientes')"
                    color="success"
                    >mdi-check</v-icon
                  >
                </td>
                <td @click="abrirFicha(props.item.id)">
                  <span v-tooltip="$moment(props.item.created_at).format('dddd, DD MMMM YYYY, hh:mm:ss')">{{
                    props.item.created_at | moment('DD/MM/YYYY')
                  }}</span>
                </td>
                <td width="1%" class="text-center">
                  <v-btn
                    v-tooltip="$t('common.delete')"
                    color="grey"
                    icon
                    @click="mostrarModal('delete', props.item.id)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>

            <template slot="no-data">
              {{ $t('common.no_registros') }}
            </template>
            <template slot="pageText" slot-scope="props">
              {{ $t('menu.empresas') }} {{ props.pageStart }} - {{ props.pageStop }} {{ $t('common.of') }}
              {{ props.itemsLength }}
            </template>
          </v-data-table>
        </v-card>
        <div class="text-center py-5">
          <v-pagination v-model="page" circle :total-visible="$vuetify.breakpoint.mobile ? 3 : 7" :length="pageCount" />
        </div>
      </v-card>
    </v-row>

    <!-- Modal de Nuevo Empresa -->
    <v-dialog v-model="dialog_new.dialog" max-width="600" :overlay="true" scrollable>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t('empresa.nueva') }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click.native="dialog_new.dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <new-company @close="dialog_new.dialog = false" />
      </v-card>
    </v-dialog>

    <!-- Modal Eliminar empresa -->
    <v-dialog v-model="dialog_delete.dialog" dialog_delete max-width="500px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t('empresa.delete') }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="dialog_delete.dialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <p>{{ $t('empresa.confirm_delete') }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-1" text @click.native="dialog_delete.dialog = false">{{
            $t('common.cancel')
          }}</v-btn>
          <v-btn
            color="red darken-1"
            text
            @click.native="
              dialog_delete.dialog = false;
              borrarempresa(empresa.id);
            "
            ><v-icon>mdi-delete</v-icon> {{ $t('common.confirm') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alerta.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
      >&nbsp;{{ alerta.texto }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { companiesUrl, deleteCompanyUrl, getHeader } from '../config/config';
import NewCompany from '../components/ehealth/company/New.vue';

export default {
  components: {
    NewCompany,
  },
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    loading: true,
    page: 1,
    pageCount: null,
    itemsPerPage: 20,
    search: '',
    snackbar: false,
    timeout: 5000,
    mode: '',
    empresa: {
      id: 0,
    },
    dialog_delete: {
      name: '',
      dialog: false,
    },
    dialog_new: {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
    },
    alerta: {
      texto: '',
      icono: '',
      color: 'info',
      tipo: 'outlined',
    },
    headers: [
      { text: 'Nombre', align: 'left', value: 'nombre' },
      { text: 'Email de Notificación', align: 'left', value: 'email_admin' },
      {
        text: 'Comparte Contactos',
        align: 'center',
        value: 'estado_noty_pacientes',
      },
      { text: 'Fecha Alta', align: 'left', value: 'created_at' },
      { text: 'Acciones', align: 'left', value: 'actions' },
    ],
    estados: [
      { color: 'red accent-1', label: 'NO' },
      { color: 'green lighten-1', label: 'SI' },
    ],
    empresas: [],
    datatable: {
      rows_per_page: 'Rows per Page',
    },
  }),
  watch: {
    page() {
      this.$scrollTo('#appRoot');
    },
  },
  created() {
    this.$http.post(companiesUrl, { id: this.authUser.id }, { headers: getHeader() }).then(
      response => {
        this.empresas = response.data.empresas;
        this.loading = false;

        // traducciones
        this.headers[0]['text'] = this.$t('common.name');
        this.headers[1]['text'] = this.$t('empresa.email_admin');
        this.headers[2]['text'] = this.$t('empresa.compartir_pacientes');
        this.headers[3]['text'] = this.$t('common.date_up');
        this.headers[4]['text'] = this.$t('common.actions');
        this.datatable.rows_per_page = this.$t('datatable.rows_per_page');
      },
      () => {
        this.$router.push('/usuarios');
      }
    );
  },

  methods: {
    listarempresas() {
      this.$http.post(companiesUrl, { id: this.authUser.id }, { headers: getHeader() }).then(response => {
        this.empresas = response.data.empresas;
      });
    },

    mostrarModal(tipo, id) {
      if (tipo === 'delete') {
        this.dialog_delete.dialog = true;
      } else if (tipo === 'nuevo') {
        this.dialog_new.dialog = true;
      }
      this.empresa.id = id;
    },
    abrirFicha(id) {
      this.$router.push('/empresa/' + id);
    },

    borrarempresa(id) {
      this.$http
        .post(deleteCompanyUrl, { id }, { headers: getHeader() })
        .then(() => {
          this.alerta.icono = 'mdi-delete';
          this.alerta.texto = this.$t('empresa.delete_success');
          this.alerta.color = 'info';
          this.snackbar = true;
          this.listarempresas();
        })
        .catch(response => {
          if (response.body && response.body.error) {
            this.alerta.texto = this.$t(response.body.error);
            this.alerta.color = 'error';
            this.snackbar = true;
          }
        });
    },
  },
};
</script>
<style scoped lang="css">
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
.loading_empresa {
  margin-top: 30px;
  text-align: center;
}
.form_empresa_nuevo {
  padding: 10px 30px;
}
.label_genero {
  margin-bottom: 0px;
}
.radio_genero {
  margin-top: 3px;
}
.titulo_modal {
  margin-top: 0px !important;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
