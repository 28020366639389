<template>
  <div>
    <form class="form_empresa_nuevo">
      <v-layout grid-list-md wrap>
        <v-flex xs12>
          <v-text-field
            v-model="company.name"
            dense
            outlined
            filled
            class="mr-2"
            :label="$t('common.name')"
            type="text"
            required
          />
        </v-flex>
        <v-flex sm8>
          <v-text-field
            v-model="company.emailAdmin"
            dense
            outlined
            filled
            class="mr-2"
            :label="$t('empresa.email_admin')"
            type="mail"
            required
          />
        </v-flex>
        <v-flex sm4>
          <v-text-field
            v-model="company.licenseLimit"
            dense
            outlined
            filled
            class="mr-2"
            :label="$t('empresa.licenses_num')"
            type="number"
            required
          />
        </v-flex>
        <v-flex sm6>
          <v-switch
            v-model="company.sharingPatients"
            class="mt-0"
            inset
            :label="$t('empresa.share_patients')"
            color="primary"
          />
        </v-flex>
        <v-flex sm6>
          <v-switch
            v-model="company.paymentModule"
            class="mt-0"
            inset
            :label="$t('empresa.payment_module')"
            color="primary"
          />
        </v-flex>
        <template v-if="company.paymentModule">
          <v-flex sm6 lg6>
            <v-switch
              v-model="company.globalPaymentAccount"
              class="mt-0"
              inset
              :label="
                company.globalPaymentAccount
                  ? $t('empresa.single_tpv_account') + ' [SI]'
                  : $t('empresa.single_tpv_account') + ' [NO]'
              "
              color="primary"
            />
          </v-flex>
          <v-flex xs6 sm6>
            <v-select
              v-model="company.paymentType"
              :items="paymentTypes"
              :label="$t('empresa.payment_type')"
              auto
              dense
              filled
              outlined
              required
              item-text="name"
              item-value="id"
            />
          </v-flex>
          <v-flex v-if="company.paymentType === '1'" xs6 sm6>
            <v-select
              v-model="company.feeType"
              :items="feeTypes"
              :label="$t('empresa.fee_type')"
              auto
              dense
              filled
              outlined
              required
              item-text="name"
              item-value="id"
            />
          </v-flex>
          <v-flex v-if="company.paymentType === '1'" xs6 sm6>
            <v-text-field
              v-model="company.feeValue"
              class="mr-2"
              outlined
              dense
              filled
              :label="company.feeType === '1' ? $t('empresa.fee_value') + ' (%)' : $t('empresa.fee_value') + ' (€)'"
              type="number"
              step="0.01"
              min="0"
              max="100"
              required
              :append-icon="company.feeType === '0' ? 'mdi-currency-eur' : ''"
            />
          </v-flex>
          <v-flex v-if="company.paymentType === '1' && company.feeType === '1'" xs6 sm6>
            <v-text-field
              v-model="company.feeMin"
              class="mr-2"
              outlined
              dense
              filled
              :label="$t('empresa.fee_min')"
              type="number"
              step="0.01"
              min="0"
              max="100"
              required
              append-icon="mdi-currency-eur"
            />
          </v-flex>
          <v-flex v-if="company.paymentType === '1' && company.feeType === '1'" xs6 sm6>
            <v-text-field
              v-model="company.feeMax"
              class="mr-2"
              outlined
              dense
              filled
              :label="$t('empresa.fee_max')"
              type="number"
              step="0.01"
              min="0"
              max="100"
              required
              append-icon="mdi-currency-eur"
            />
          </v-flex>
        </template>

        <v-flex lg12 class="py-3 text-right">
          <v-btn text color="grey darken-1" class="mr-2" @click="$emit('close')">{{ $t('common.cancel') }}</v-btn>
          <v-btn depressed :disabled="loading" color="primary" @click="createCompany">{{ $t('common.create') }}</v-btn>
        </v-flex>
        <button v-show="false" v-shortkey="['ctrl', 'alt', 'o']" @shortkey="showComponentsHidden()" />
      </v-layout>
    </form>
  </div>
</template>

<script>
import { createCompanyUrl, getHeader } from '../../../config/config';
import { mapActions } from 'vuex';

export default {
  name: 'NewCompany',
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    company: {
      valid: true,
      id: 0,
      name: '',
      sharingPatients: false,
      emailAdmin: '',
      licenseLimit: 1,
      paymentModule: false,
      globalPaymentAccount: false,
      paymentType: '0',
      feeType: '0',
      feeValue: 0,
      feeMin: 2.5,
      feeMax: 4,
    },
    showAdditionalSettings: false,
    loading: false,
    paymentTypes: [
      { id: '0', name: 'Premium' },
      { id: '2', name: 'Premium con Límites' },
    ],
    feeTypes: [
      { id: '0', name: 'Fijo (€)' },
      { id: '1', name: 'Por Porcentaje' },
    ],
    snackbarText: '',
  }),

  methods: {
    ...mapActions('layout', ['showAlert']),

    validateForm() {
      this.snackbarText = '';
      const regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      this.company.emailAdmin = this.company.emailAdmin.trim();
      if (this.company.name.length < 3) {
        this.snackbarText = this.$t('empresa.nombre_required');
      } else if (this.company.emailAdmin === '') {
        this.snackbarText = this.$t('errors.email_required');
      }

      if (this.company.emailAdmin !== '' && !regex.test(this.company.emailAdmin)) {
        this.snackbarText = this.$t('errors.email_invalid');
      }
      return this.snackbarText === '';
    },

    createCompany() {
      if (!this.validateForm()) {
        this.showAlert({ color: 'error', message: this.snackbarText });
      } else if (!this.loading) {
        this.loading = true;
        this.$http
          .post(createCompanyUrl, this.company, { headers: getHeader() })
          .then(response => {
            this.showAlert({ color: 'success', message: this.$t('success.save_common') });
            this.$router.push('/empresa/' + response.data.id);
          })
          .catch(e => {
            this.$log.error(e);
            const msg = e?.body?.error ? e.body.error : this.$t('errors.bad_request');
            this.showAlert({ color: 'error', message: msg });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    showComponentsHidden() {
      this.showAdditionalSettings = true;
      this.paymentTypes.push({ id: '1', name: 'Freemium' });
    },
  },
};
</script>
<style scoped lang="css">
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
.form_empresa_nuevo {
  padding: 10px 30px;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
